import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LocationFilters } from '../../models/filters/location-filters';
import { environment } from '../../../../../environments/environment';
import { injectQuery, injectQueryClient } from '@ngneat/query';
import { DataQueryServiceConfiguration } from './data-query-service-configuration';
import { catchError, concat, concatMap, defer, EMPTY, expand, filter, from, map, mergeMap, Observable, of, OperatorFunction, throwError, toArray } from 'rxjs';
import { PagedResultWithPointers } from '../../models/paging/paged-result';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
    providedIn: 'root'
})
export class DataQueryService {
    private readonly query = injectQuery();
    private readonly queryClient = injectQueryClient();

    private activeNotification?: NotificationRef;

    constructor(private http: HttpClient, private notificationService: NotificationService) { }

    prefetch(): Observable<any> {
        return of(void 0);
    }

    get<T, Y extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, parameters?: Y) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }).pipe(catchError((error) => this.handleError(error))),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    protected prefetchGet<T, Y extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, parameters?: Y) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }).pipe(catchError((error) => this.handleError(error))),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    getWithTransform<T, Y, Z extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, transform: OperatorFunction<T, Y>, transformedKey: string, parameters?: Z) {
        return this.query({
            queryKey: [dataServiceConfiguration.key + '-' + transformedKey, parameters] as const,
            queryFn: () => this.get(dataServiceConfiguration, parameters).result$.pipe(
                filter((results) => !!results.data),
                map((results) => results.data!),
                transform),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    protected prefetchGetWithTransform<T, Y, Z extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, transform: OperatorFunction<T, Y>, parameters?: Z) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }).pipe(catchError((error) => this.handleError(error))).pipe(transform),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    post<T, Y = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, body?: Y) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(catchError((error) => this.handleError(error))),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    prefetchPost<T, Y = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, body?: Y) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(catchError((error) => this.handleError(error))),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    postWithTransform<T, Y, Z = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, transform: OperatorFunction<T, Y>, body?: Z) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(catchError((error) => this.handleError(error))).pipe(transform),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    postWithTransformPaged<T, Y, Z = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, transform: OperatorFunction<T[], Y>, body?: Z) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, 'all', body] as const,
            queryFn: () => this.postAllPages(dataServiceConfiguration, body).pipe(catchError((error) => this.handleError(error)), transform),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    private executePagedPost<T, Y>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, body: Y) {
        return this.http.post<PagedResultWithPointers<T, Y>>(dataServiceConfiguration.url, body, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(catchError((error) => this.handleError(error)));
    }

    private postAllPages<T, U>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, body?: U): Observable<T[]> {
        return this.executePagedPost(dataServiceConfiguration, body).pipe(
            expand(({ nextPage }) => nextPage ? this.executePagedPost(dataServiceConfiguration, nextPage) : EMPTY),
            concatMap(({ results }) => results),
            toArray(),
        );
    }

    prefetchPostWithTransform<T, Y, Z = undefined>(dataServiceConfiguration: DataQueryServiceConfiguration<T>, transform: OperatorFunction<T, Y>, body?: Z) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(catchError((error) => this.handleError(error))).pipe(transform),
            throwOnError: false,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    refetchQuery(key: string, bodyOrParameters: any) {
        return from(this.queryClient.refetchQueries({
            predicate: x => x.queryKey == [key, bodyOrParameters] as const
        }));
    }

    refetchAnyQuery(key: string) {
        return from(this.queryClient.refetchQueries({
            predicate: x => x.queryKey[0] == key
        }));
    }

    private handleError(error: HttpErrorResponse) {
        if (this.activeNotification) {
            this.activeNotification.hide();
        }
        
        if (error.headers.has('BadRequestMessage')) {
            this.activeNotification = this.notificationService.show({
                content: error.headers.get('BadRequestMessage')!,
                type: { style: "error", icon: true },
                hideAfter: 5000,
                cssClass: ["validation-message-notification"]
            });
        }
        else {
            this.activeNotification = this.notificationService.show({
                content: 'An error occurred. Please try again.',
                type: { style: "error", icon: true },
                hideAfter: 5000,
                cssClass: ["validation-message-notification"]
            });
        }

        return throwError(() => error);
    }
}